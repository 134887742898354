import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { NumberInput, NumberInputProps, useInput } from 'react-admin';

import lodash from 'lodash';

interface Props extends NumberInputProps {
    validateOnlyIfNotEmpty?: boolean,
    // source: string;
    // resource?: string;
    // fullWidth?: boolean;
}

const CustomNumberInput = (props: Props) => {
    //const { values } = useFormState();
    // const sumplattava = useWatch({ name: 'public.sumplattava' });
    // const showInput = 
    //     sumplattava != undefined
    //         ? sumplattava
    //         : false;

    // if (!showInput) return null;
    
    //const i = useInput(props);
    //console.log(i);

    return (
        <NumberInput
            // source={source}
            // resource={resource}
            // fullWidth={fullWidth}
            {...props}
        />
    );
};

CustomNumberInput.defaultProps = {
    validateIfNotEmpty: false,
};

export default CustomNumberInput;
