import * as React from 'react';
import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppBar from './AppBar';
import Menu from './Menu';
import CustomBreadcrumb from './Breadcrumb';
// import tours from '../tours/tours';
import { AppLocationContext } from "@react-admin/ra-navigation";

export default (props: LayoutProps) => {
    return (
        <AppLocationContext>
            <ReactQueryDevtools initialIsOpen={false} />
            <Layout
                {...props}
                appBar={AppBar}
                menu={Menu}
                breadcrumb={<CustomBreadcrumb/>}
                //tours={tours}
            />
        </AppLocationContext>
    );
};
