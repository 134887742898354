import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
    WithListContext,
    DatagridLoading,
} from 'react-admin';
import { useMediaQuery, Theme, Typography } from '@mui/material';

import { Link } from 'react-router-dom';
import CustomStatusField from '../controls/CustomStatusField';
import GroupListAside from './GroupListAside';
import SchoolFullNameField from '../school/SchoolFullNameField';
import get from 'lodash/get';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';

const groupFilters = [
    <SearchInput source="q" alwaysOn />,
];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}
            <SelectColumnsButton />
            {/* <CreateButton /> */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const GroupList = () => {

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = ['name','status','public.archived'];
    
    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? groupFilters : undefined}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ type: '4'}}
            perPage={25}
            aside={<GroupListAside />}
            actions={<ListActions />}
            debounce={1000}
        >
            <WithListContext render={({ isLoading, isFetching, data }) => (
                isLoading || isFetching ? (<DatagridLoading nbChildren={4} nbFakeLines={8} />) : (<DatagridConfigurable
                //optimized
                omit={['guid']}
                rowClick="edit"
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                {/* {columns} */}
                <TextField source="guid" key="guid" sortable={sortable.includes('guid')} />
                <TextField source="name" key="name" sortable={sortable.includes('name')} />
                <FunctionField source="public.archived" key="public.archived" render={record => record.public.archived ? (record.private && record.private.archived_date ? <DateField source="private.archived_date" key="private.archived_date" showTime={false} locales="fi-FI" /> : <DateField source="author.modified" key="author.modified" showTime={false} locales="fi-FI" />) : ""} sortable={sortable.includes('public.archived')} />
                <FunctionField source="parent_group" key="parent_group" render={record => record.parent_group.map(pg => <Link style={{textDecoration: 'none'}} className='inner_link' to={('/school/'+pg.guid)} onClick={(e) => e.stopPropagation()}><Typography variant="body2" display="flex" flexWrap="nowrap" alignItems="center" component="div">{pg.name}</Typography></Link>)} sortable={sortable.includes('parent_group')} />

                <CustomStatusField source="status" key="status" url="extralist/group/statuses" sortable={sortable.includes('status')} />
            </DatagridConfigurable>
            ))} />
        </List>
    );
};

export default GroupList;
