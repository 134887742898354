import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { Identifier, RaRecord, Edit, SimpleForm, SimpleShowLayout, List, Datagrid, TextField, TextInput, SearchInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore, useRecordContext, useResourceContext, BooleanInput, BooleanField } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import CustomSelectInput from './CustomSelectInput';
import MovingIcon from '@mui/icons-material/Moving';
import { Grid, Box, Typography } from '@mui/material';
import CustomUserList from './CustomUserList';
import user from '../user';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
    storeKey?: string;
}

const GroupEdit = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    //console.log("CustomUserGroupList Edit", record, resource);
    return (
        <Edit
            resource={resource}
            id={record.id}
            /* disable the app title change when shown */
            title=" "
            className='cgl-dg-edit'
        >
            <SimpleForm className='cgl-dg-edit-form' toolbar={false}>
                <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Perustiedot</Typography>
                        <Typography variant="body1" paragraph>
                            Nimi: {record.group.name} {record.public.archived && <span>(arkistoitu)</span>}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Liittymiskoodi: {record.group.public.code}
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Ryhmäntyyppi: {record.group.public.group_type}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>Materiaalit</Typography>
                        <Datagrid data={record.group.public.materials} bulkActionButtons={false} style={{marginBottom:'30px'}}>
                            <TextField source="sarja" sortable={false} />
                            <TextField source="kirja" sortable={false} />
                            <TextField source="isbn" sortable={false} />
                            <BooleanField source="jaettava" sortable={false} />
                        </Datagrid>
                    </Grid>
                </Grid>

                {/* <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Opettajat</Typography>
                        <CustomUserList disableSyncWithLocation storeKey='schoolGroupTeacherList' resource='teacher' filter={{'roletype':'0,1','status':'0,2,3','groups_status':'0,2,3'}} customFilters={['group']} showExtra={['']} hideFields={['email','role','subjects']} sort={{ field: 'public.lastname', order: 'ASC' }} bulkActionButtons={false} emptyIcon={user.iconTeacher}><span /></CustomUserList>
                    </Grid>
                </Grid>

                {record?.public?.group_type?.indexOf("comprehensiveschool") < 0 && <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Opiskelijat</Typography>
                        <CustomUserList disableSyncWithLocation storeKey='schoolGroupStudentList' resource='student' customFilters={['group']} showExtra={['']} hideFields={['subjects','role']} sort={{ field: 'public.lastname', order: 'ASC' }} bulkActionButtons={false} emptyIcon={user.iconStudent}><span /></CustomUserList>
                    </Grid>
                </Grid>}

                {record?.public?.group_type?.indexOf("comprehensiveschool") > -1 && <Grid container width={{ xs: '100%' }} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" paragraph>Oppilaat</Typography>
                        <CustomUserList disableSyncWithLocation storeKey='schoolGroupPupilList' resource='pupil' customFilters={['parent_group']} showExtra={['search']} hideFields={[]} sort={{ field: 'public.lastname', order: 'ASC' }} bulkActionButtons={false} emptyIcon={user.iconPupil}><span /></CustomUserList>
                    </Grid>
                </Grid>} */}
                
            </SimpleForm>
        </Edit>
    );
};

const CustomUserGroupList = (props: Props) => {

    const translate = useTranslate();
    const cRowClick = (id: Identifier, resource: string, record: RaRecord) => {
        //console.log('cRowClick', id, resource, record);
        return '/group/'+record.group.guid;
    };
    const parent = useEditContext();
    //console.log('parent',parent);
    var filter = {};

    if (props.filter)
        filter = props.filter;

    if (props.customFilters?.includes("parent_guid"))
        filter["parent_guid"] = parent.record.group_guid;

    if (props.customFilters?.includes("user_guid"))
        filter["user_guid"] = parent.record.user_guid;

    return (
        <List sx={{margin: '20px 10px 0px 70px'}} {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            {/* <Datagrid bulkActionButtons={props.bulkActionButtons} expand={<GroupEdit />} expandSingle={true} rowClick="expand" className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.fields.name')} sortable={props.sortable} />}
            </Datagrid> */}
            <Datagrid bulkActionButtons={props.bulkActionButtons} rowClick={cRowClick} className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.name', 2)} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

CustomUserGroupList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
    storeKey: 'customUserGroupList',
};

export default CustomUserGroupList;
