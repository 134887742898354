import * as React from 'react';
import { List, Datagrid, TextField, DateField, DateInput, useTranslate, useEditContext, ListProps, useStore } from 'react-admin';
import CustomEmpty from './CustomEmpty';
import GridOffIcon from '@mui/icons-material/GridOff';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
    uselog: string;
}

const CustomLogEventList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();

    // päivämäärä rajaus mietittävä tarkemmin, kun aletaan käyttää elasticin logeja. Ongelmaksi saattaa muodostua, jos default aikavälillä tulosjoukko on 0 kpl niin ei näy hakulaatikoita???
    // const fromDay= new Date().toISOString().substring(0, 10);
    // const toDay= new Date().toISOString().substring(0, 10);

    //const filter = props.customFilters?.includes("resource_guid") ? {"resource_guid": parent.record.guid, "uselog": props.uselog, "date_from": fromDay, "date_to": toDay} :  undefined;
    const filter = props.customFilters?.includes("resource_guid") ? {"resource_guid": parent.record.guid, "uselog": props.uselog} :  undefined;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<DateInput source='date_from' label={translate(`resources.logevent.${props.uselog}.filter.date_from`)} alwaysOn />,<DateInput source='date_to' label={translate(`resources.logevent.${props.uselog}.filter.date_to`)} alwaysOn />] : undefined} empty={<CustomEmpty icon={GridOffIcon} />}>
            <Datagrid bulkActionButtons={false}>
                {!props.hideFields?.includes("date") && <DateField showTime={true} source="date" label={translate(`resources.logevent.${props.uselog}.fields.date`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("author") && <TextField source="author" label={translate(`resources.logevent.${props.uselog}.fields.author`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("title") && <TextField source="title" label={translate(`resources.logevent.${props.uselog}.fields.title`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("subtitle") && <TextField source="subtitle" label={translate(`resources.logevent.${props.uselog}.fields.subtitle`)} sortable={props.sortable} />}
                {!props.hideFields?.includes("description") && <TextField source="description" label={translate(`resources.logevent.${props.uselog}.fields.description`)} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

CustomLogEventList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
};

export default CustomLogEventList;
