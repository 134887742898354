import * as React from 'react';
import { useRecordContext } from 'react-admin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import {get,find} from 'lodash';

interface Props {
    source: string;
    label?: string;
    sortable?: boolean;
}

const RoleNameField = (props: Props) => {
   
    const record = useRecordContext();
    
    let role = get((find(record.group,{type:2}) || record.group[0]),'role_name');
    let isAdmin = get((find(record.group,{type:2}) || record.group[0]),'public.isadmin', false);

    return (
        <div>{role}{isAdmin ? ', Ylläpitäjä': ''}</div>
    );
};

RoleNameField.defaultProps = {
    source: "group",
    label: "",
    sortable: false,
};

export default RoleNameField;
