import * as React from 'react';

import { useDataProvider, FilterList, FilterListItem } from 'react-admin';
import { useQuery } from 'react-query';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useStore, useListFilterContext } from 'react-admin';
import { set } from 'date-fns';

export interface CustomFilterListProps {
    source?: string;
    optionValue?: string;
    optionText?: string;
    url: string;
    label: string;
    icon: any;
    variant?: 'filled' | 'outlined';
    sx?: SxProps;
    className?: string;
}

interface ResItem {
    item_id: any;
    item_title: any;
}

const CustomFilterList = (props: CustomFilterListProps) => {

    const {
        source = '',
        variant,
        //label = translate('ra.action.search'),
        optionValue = 'item_id',
        optionText = 'item_title',
        url,
        label,
        icon,
        className,
        ...rest
    } = props;

    let params = '';


    const {resource} = useListFilterContext();

    const [filterListSettings, setFilterListSettings] = useStore(`${resource}.filterList`, "");
    
    const getFilterListMode: any = () => {
        return filterListSettings !== '' && filterListSettings[source] && filterListSettings[source].mode ? filterListSettings[source].mode : '';
    };

    const setFilterListMode: any = (c) => {
        let settings = filterListSettings !== '' ? JSON.parse(JSON.stringify(filterListSettings)) : {};
        settings = {...settings, [source]: {mode: c}};
        setFilterListSettings(settings);
    };
    //console.log({...rest});
    const dataProvider = useDataProvider();
    // const [customChoices, setCustomChoices] = useState<ResItem[]>();
    params = "source=" + source;

    const { data } = useQuery(
        [url, 'getAny', params], 
        () => dataProvider.getAny(url, params)
    );

    if (!data) return null;

    const customChoices = data.data;

    if (!customChoices) return null;

    const handleOnClick: any = () => {
        getFilterListMode() !== '' ? setFilterListMode('') : setFilterListMode('_close');
    };

    return (
        <div style={{display: 'grid'}}>
            <Button onClick={handleOnClick} className={clsx(`close_button${getFilterListMode()}`)}>
                { getFilterListMode() === '' ? (<ArrowDropDownIcon />) : (<ArrowRightIcon />) }
            </Button>
            <FilterList 
                label={`${label}${getFilterListMode()}`} 
                icon={icon} 
                className={clsx(`OopFilterList${getFilterListMode()}`, `oop-filterlist-${source}${getFilterListMode()}`, className)}
                //onClick={handleOnClick}
                {...rest}>
                {customChoices.map(choice => (
                    <FilterListItem
                        label={choice[optionText]}
                        key={choice[optionValue]}
                        value={JSON.parse(
                            `{"${source}": "${choice[optionValue]}"}`
                        )}
                    />
                ))}
            </FilterList>
        </div>
    );
};

export default CustomFilterList;
