import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { List, Datagrid, TextField, TextInput, SearchInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomSelectInput from './CustomSelectInput';
import RoleNameField from '../user/RoleNameField';
import SubjectListField from '../user/SubjectListField';
import PeopleIcon from '@mui/icons-material/People';
import CustomLogAsTeacherButton from './CustomLogAsTeacherButton';
import CustomLogAsStudentButton from './CustomLogAsStudentButton';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
    storeKey?: string;
    emptyIcon?: any;
}

//const customRowClick = (id: any, basePath: any, record: any) => {console.log("customRowClick", id, basePath, record); return false;};
    //return "https://oppimateriaalit-aspa.otava.fi/#/pupil";


const CustomUserList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    var filter = {};
    var emptyIcon = PeopleIcon;

    if (props.filter)
        filter = props.filter;

    if (props.emptyIcon)
        emptyIcon = props.emptyIcon;
    //const filter = props.customFilters?.includes("subscriber_code") ? {"subscriber_guid": parent.record.parent_group[0].guid} :  undefined;
    if (props.customFilters?.includes("group"))
        filter["group"] = parent.record.guid;

    if (props.customFilters?.includes("parent_group"))
        filter["parent_group"] = parent.record.guid;
    //skip_parent
    if (props.customFilters?.includes("skip_parent"))
        filter["skip_parent"] = true;

    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<SearchInput source="q" size="small" alwaysOn />] : undefined} empty={<CustomEmpty icon={emptyIcon} />}>
            <Datagrid bulkActionButtons={props.bulkActionButtons} rowClick="edit" /* rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}} */>
                {!props.hideFields?.includes("lastname") && <TextField source="public.lastname" label={translate('resources.userlist.fields.lastname')} sortable={props.sortable} />}
                {!props.hideFields?.includes("middlename") && <TextField source="public.middlename" label={translate('resources.userlist.fields.middlename')} sortable={props.sortable} />}
                {!props.hideFields?.includes("firstname") && <TextField source="public.firstname" label={translate('resources.userlist.fields.firstname')} sortable={props.sortable} />}
                {!props.hideFields?.includes("code") && <TextField source="public.code" label={translate('resources.userlist.fields.code')} sortable={props.sortable} />}
                {!props.hideFields?.includes("username") && <TextField source="username" label={translate('resources.userlist.fields.username')} sortable={props.sortable} />}
                {!props.hideFields?.includes("email") && <TextField source="public.email" label={translate('resources.userlist.fields.email')} sortable={props.sortable} />}
                {!props.hideFields?.includes("role") && <RoleNameField label={translate('resources.userlist.fields.role')} sortable={props.sortable} />}
                {!props.hideFields?.includes("subjects") && <SubjectListField label={translate('resources.userlist.fields.subjects')} sortable={props.sortable} />}
                {!props.hideFields?.includes("loginteacher") && <CustomLogAsTeacherButton sortable={props.sortable} />}
                {!props.hideFields?.includes("loginstudent") && <CustomLogAsStudentButton sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

CustomUserList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
    storeKey: 'customUserList',
};

export default CustomUserList;
