import React from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';

import Welcome from './Welcome';

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    return isXSmall ? (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
        </Grid>
    ) : isSmall ? (
        <Grid container padding={1}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
        </Grid>
    ) : (
        <Grid container padding={1}>
            <Grid item xs={12}>
                <Welcome />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
