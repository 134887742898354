import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { List, Datagrid, TextField, TextInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomSelectInput from './CustomSelectInput';
import CustomUserLicenseField from './CustomUserLicenseField';
import ProductFullNameField from '../product/ProductFullNameField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
}

const CustomUserLicenseByUserList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    const filter = props.customFilters?.includes("user_guid") ? {"user_guid": parent.record.guid} :  undefined;
    //console.log(filter);
    return (
        <List {...props} actions={false} filter={filter} filters={props.showExtra?.includes("search") ? [<CustomSelectInput url="extralist/subscription/groups" source="group_guid" label={translate('resources.userlicense.fields.groups')} filter="guid" alwaysOn />] : undefined} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={props.bulkActionButtons}>
                {!props.hideFields?.includes("product") && <ProductFullNameField source="product" key="product.name" createLink={true} useIcon={false} sortable={props.sortable} />}
                {!props.hideFields?.includes("licenses") && <CustomUserLicenseField source="licenses" label={translate('resources.userlicense.fields.licenses')} />}
            </Datagrid>
        </List>
    );
};

CustomUserLicenseByUserList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
};

export default CustomUserLicenseByUserList;
