import {
    mergeTranslations,
    TranslationMessages as BaseTranslationMessages,
} from 'react-admin';
import finnishMessages from 'ra-language-finnish';
import { RaTreeTranslationMessages } from '@react-admin/ra-tree';
import { raAuditLogLanguageEnglish } from '@react-admin/ra-audit-log';

export interface TranslationMessages
    extends RaTreeTranslationMessages,
        BaseTranslationMessages {}

const customFinnishMessages: TranslationMessages = mergeTranslations(
    finnishMessages,
    raAuditLogLanguageEnglish,
    {
        'ra-form-layout': {
            action: {
                next: 'Seuraava',
            },
        },
        'ra-tree': {
            action: {
                add_root: 'Add a category of products',
            },
        },
        'ra-search': {
            result: `1 result |||| %{smart_count} results`,
        },
        'ra-realtime': {
            notification: {
                lock: {
                    lockedByMe: 'Lukittu sinulle',
                    lockedBySomeoneElse: 'Lukittu jollekin toiselle',
                    unlocked: 'Lukitus vapautettu',
                },
            },
        },
        'ra-preferences': {
            action: {
                choose_columns: 'Valitse sarake',
                toggle_theme: 'Vaihda teema',
            },
        },
        ra: {
            action: {
                select_columns: 'Valitse sarakkeet',
                toggle_theme: 'Vaihda teema',
            },
            page: {
                empty: 'Ei tuloksia',
            },
            saved_queries: {
                label: 'Tallennetut',
                query_name: 'Kyselyn nimi',
                new_label: 'Tallenna nykyinen kysely',
                new_dialog_title: 'Tallenna nykyinen kysely nimellä',
                remove_label: 'Poista tallennettu kysely',
                remove_label_with_name: 'Poista kysely "%{name}"',
                remove_dialog_title: 'Poista tallennettu kysely?',
                remove_message: 'Oletko varma, että haluat poistaa kyselyn?',
                help: 'Suodata ensin lista ja tallenna kysely tulevaan käyttöä varten.',
            },
        },
        menu: {
            audience: 'Käyttäjät',
            sales: 'Testi',
            catalog: 'Catalog',
            customers: 'Customers',
        },
        pos: {
            search: 'Etsi',
            configuration: 'Asetukset',
            language: 'Kieli',
            theme: {
                name: 'Theme',
                light: 'Light',
                dark: 'Dark',
            },
            filter: 'Filtered by',
            dashboard: {
                welcome: {
                    title: 'Teretulemast uuteen aspa-liittymään.',
                    subtitle: 'Tämä on uusi aspa-liittymä.',
                },
            },
        },
        resources: {
            user: {
                name: 'Ylläpitäjä |||| Ylläpitäjät |||| Kaikki ylläpitäjät',
                fields: {
                    // group: 'Ryhmä',
                    // groups: 'Ryhmät',
                    last_login: 'Kirjautunut',
                    registered: 'Rekisteröitynyt',
                    name: 'Nimi',
                    username: 'Tunnus',
                    status: 'Tila',
                    password: 'Salasana',
                    public: {
                        firstname: 'Etunimi',
                        middlename: 'Toinen nimi',
                        lastname: 'Sukunimi',
                        description: 'Kuvaus',
                        login_not_allowed: 'Estä kirjautuminen',
                        validfrom: 'Alkaa',
                        validto: 'Päättyy',
                        profile_locked: 'Tunnus lukittu',
                    },
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                    group: {
                        valid: 'Tunnuksen voimassaoloaika',
                        valid_desc:
                            'Jätä kentät tyhjiksi, jos et halua asettaa voimassaoloaikaa.',
                        ofduty: 'Vapaalla',
                        isadmin: 'Oikeudet päivittää koulun tietoja',
                        role: 'Rooli',
                        subrole: 'Opetusaineet',
                    },
                    applicationToAdd: 'Palvelu',
                    application: {
                        name: 'Palvelu',
                        isadmin: 'Ylläpitäjä',
                        role: 'Rooli',
                        status: 'Tila',
                    },
                },
                filters: {
                    status: 'Tila',
                    status_close: 'Tila',
                    schools: 'Oppilaitos',
                    'schools.search': 'Hae oppilaitosta',
                    schools_close: 'Oppilaitos',
                },
                tabs: {
                    profile: 'Perustiedot',
                    application:'Palvelut',
                    logs:'Tapahtumaloki'
                },
                status: {
                    0: 'aktiivinen',
                    1: 'Poistettu',
                    2: 'Odottaa vahvistusta',
                    3: 'Rekisteröitymätön',
                    10: 'Mahdollinen tupla',
                },
                help: {
                    modifyuserapp: 'Muokkaa käyttäjän palvelu asetuksia.',
                },
            },
            teacher: {
                name: 'Opettaja |||| Opettajat',
                actions: {
                    back: 'Takaisin koulutietoihin',
                    approve: 'Vahvista',
                    changepassword: 'Vaihda salasana',
                    reapprove: 'Uusi vahvistuspyyntö',
                },
                fields: {
                    last_login: 'Kirjautunut',
                    registered: 'Rekisteröitynyt',
                    modified: 'Muokattu',
                    name: 'Nimi',
                    username: 'Työsähköpostiosoite',
                    status: 'Tila',
                    password: 'Salasana',
                    public: {
                        firstname: 'Etunimi',
                        middlename: 'Toinen nimi',
                        lastname: 'Sukunimi',
                        description: 'Kuvaus',
                        learning_environment_1: 'Google Classroom',
                        learning_environment_2: 'Office 365',
                        learning_environment_3: 'Kampus',
                        learning_environment_4: 'Moodle',
                        learning_environment_5: 'Itslearning',
                        learning_environment_6: 'Peda.net',
                        learning_environment_free: 'Joku muu, mikä?',
                        login_not_allowed: 'Estä kirjautuminen',
                        validfrom: 'Alkaa',
                        validto: 'Päättyy',
                        profile_locked: 'Tunnus lukittu',
                    },
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                    school: 'Oppilaitos',
                    group: {
                        valid: 'Tunnuksen voimassaoloaika',
                        valid_desc:
                            'Jätä kentät tyhjiksi, jos et halua asettaa voimassaoloaikaa.',
                        ofduty: 'Vapaalla',
                        isadmin: 'Oikeudet päivittää koulun tietoja',
                        role: 'Rooli',
                        subrole: 'Opetusaineet',
                    },
                    application: 'Palvelu',
                    services: '',
                },
                tabs: {
                    profile: 'Perustiedot',
                    schools_and_groups: 'Koulut ja ryhmät',
                    materials: 'Käytössä',
                    merge: 'Yhdistäminen',
                    schools: 'Oppilaitokset',
                    classes: 'Luokat',
                    role: 'Rooli',
                    subject: 'Opetusaineet',
                    isadmin: 'Ylläpitäjä',
                    ofduty: 'Vapaalla',
                    logs: 'Loki',
                    status: 'Tila',
                    application: 'Palvelut',
                },
                page: {
                    delete: 'Poista opettaja',
                    create: 'Lisää opettaja',
                    addSchool: 'Lisää oppilaitos',
                    addApp: 'Lisää palvelu',
                    changepassword: {
                        title: 'Salasanan vaihtaminen',
                        oldpassword: 'Vanha salasana',
                        newpassword: 'Syötä uusi salsana',
                        responses: {
                            401: 'Vanha salasana virheellinen',
                            404: 'Virheellinen käyttäjä',
                            500: 'Salasanan vaihto epäonnistui, yritä hetken päästä uudelleen.',
                            200: 'Salasana on vaihdettu',
                        },
                    },
                },
                status: {
                    0: 'Aktiivinen |||| Aktiivisia',
                    1: 'Poistettu |||| Poistettuja',
                    2: 'Odottaa vahvistusta |||| Odottaa vahvistusta',
                    3: 'Rekisteröitymätön |||| Rekisteröimättömiä',
                },
                help: {
                    createform:
                        'Luo uusi opettaja ja liitä hänet oppilaitokseen. Tunnus on voimassa toistaiseksi, mikäli voimassaoloa ei määritetä.',
                    modifyschoolform: 'Muokkaa opettajan koulutietoja.',
                },
                empty_header: 'Ei opettajia',
            },
            student: {
                name: 'Opiskelija |||| Opiskelijat',
                actions: {
                    back: 'Takaisin koulutietoihin',
                    approve: 'Vahvista',
                    changepassword: 'Vaihda salasana',
                    reapprove: 'Uusi vahvistuspyyntö',
                },
                fields: {
                    last_login: 'Kirjautunut',
                    registered: 'Rekisteröitynyt',
                    modified: 'Muokattu',
                    name: 'Nimi',
                    username: 'Kirjavälityksen tunnus',
                    status: 'Tila',
                    password: 'Salasana',
                    email: 'Sähköpostiosoite',
                    alt_email: 'Vaihtoehtoiset sähköpostiosoitteet',
                    public: {
                        email: 'Sähköpostiosoite',
                        emails: 'Vaihtoehtoiset sähköpostiosoitteet',
                        firstname: 'Etunimi',
                        middlename: 'Toinen nimi',
                        lastname: 'Sukunimi',
                        description: 'Kuvaus',
                        login_not_allowed: 'Estä kirjautuminen',
                        validfrom: 'Alkaa',
                        validto: 'Päättyy',
                        profile_locked: 'Tunnus lukittu',
                    },
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                    group: {
                        valid: 'Tunnuksen voimassaoloaika',
                        valid_desc:
                            'Jätä kentät tyhjiksi, jos et halua asettaa voimassaoloaikaa.',
                        validfrom: 'Alkaa',
                        validto: 'Päättyy',
                        ofduty: 'Vapaalla',
                        isadmin: 'Oikeudet päivittää koulun tietoja',
                        role: 'Rooli',
                        subrole: 'Opetusaineet',
                    },
                    application: 'Palvelu',
                },
                tabs: {
                    profile: 'Perustiedot',
                    schools_and_groups: 'Koulut ja ryhmät',
                    materials: 'Käytössä',
                    merge: 'Yhdistäminen',
                    schools: 'Oppilaitokset',
                    classes: 'Luokat',
                    role: 'Rooli',
                    subject: 'Opetusaineet',
                    isadmin: 'Ylläpitäjä',
                    ofduty: 'Vapaalla',
                    logs: 'Loki',
                    status: 'Tila',
                    application: 'Palvelut',
                },
                page: {
                    delete: 'Poista opettaja',
                    create: 'Lisää opettaja',
                    addSchool: 'Lisää oppilaitos',
                    addApp: 'Lisää palvelu',
                    changepassword: {
                        title: 'Salasanan vaihtaminen',
                        oldpassword: 'Vanha salasana',
                        newpassword: 'Syötä uusi salsana',
                        responses: {
                            401: 'Vanha salasana virheellinen',
                            404: 'Virheellinen käyttäjä',
                            500: 'Salasanan vaihto epäonnistui, yritä hetken päästä uudelleen.',
                            200: 'Salasana on vaihdettu',
                        },
                    },
                },
                status: {
                    0: 'Aktiivinen |||| Aktiivisia',
                    1: 'Poistettu |||| Poistettuja',
                    2: 'Odottaa vahvistusta |||| Odottaa vahvistusta',
                    3: 'Rekisteröitymätön |||| Rekisteröimättömiä',
                },
                help: {
                    createform:
                        'Luo uusi opettaja ja liitä hänet oppilaitokseen. Tunnus on voimassa toistaiseksi, mikäli voimassaoloa ei määritetä.',
                    modifyschoolform: 'Muokkaa opettajan koulutietoja.',
                },
                empty_header: 'Ei opiskelijoita',
            },
            pupil: {
                name: 'Oppilas |||| Oppilaat',
                codes: [
                    'aalto',
                    'aamu',
                    'aarre',
                    'ahven',
                    'aine',
                    'aisti',
                    'aito',
                    'akseli',
                    'alasin',
                    'alku',
                    'alpakka',
                    'askel',
                    'atlantti',
                    'atomi',
                    'aurinko',
                    'avain',
                    'elo',
                    'hanko',
                    'happi',
                    'harakka',
                    'harju',
                    'hattu',
                    'hauki',
                    'haukka',
                    'heitto',
                    'helmi',
                    'herhiläinen',
                    'hiekka',
                    'hiili',
                    'hiiri',
                    'hiutale',
                    'hopea',
                    'huhta',
                    'huhti',
                    'huippu',
                    'hukka',
                    'hylly',
                    'hymy',
                    'höyhen',
                    'ikkuna',
                    'ilo',
                    'ilta',
                    'ilves',
                    'into',
                    'jalava',
                    'jalo',
                    'joki',
                    'jono',
                    'jousi',
                    'juhla',
                    'juuri',
                    'jälki',
                    'jälki',
                    'kaarre',
                    'kaiku',
                    'kakku',
                    'kala',
                    'kallio',
                    'kameli',
                    'kani',
                    'kaniini',
                    'kansi',
                    'karhu',
                    'kartta',
                    'karviainen',
                    'kasse',
                    'kasvi',
                    'kaura',
                    'kauris',
                    'keino',
                    'keinu',
                    'kello',
                    'keltainen',
                    'kerho',
                    'kerros',
                    'kesä',
                    'kettu',
                    'kevät',
                    'kielo',
                    'kierros',
                    'kierto',
                    'kimalainen',
                    'kipinä',
                    'kirahvi',
                    'kirja',
                    'kirjain',
                    'kirjasto',
                    'kirsu',
                    'kissa',
                    'kivi',
                    'koira',
                    'koivu',
                    'koju',
                    'kontio',
                    'korento',
                    'kori',
                    'koti',
                    'koulu',
                    'kuikka',
                    'kuitu',
                    'kukka',
                    'kulku',
                    'kulma',
                    'kulta',
                    'kuohu',
                    'kupari',
                    'kurki',
                    'kurre',
                    'kuu',
                    'kuusi',
                    'kuva',
                    'kyyhky',
                    'käpy',
                    'käpälä',
                    'lahja',
                    'lahti',
                    'laine',
                    'laituri',
                    'laiva',
                    'lammas',
                    'lasi',
                    'lasku',
                    'latva',
                    'laulu',
                    'laulu',
                    'lehti',
                    'lehto',
                    'leijona',
                    'leikki',
                    'leipä',
                    'lento',
                    'leopardi',
                    'leppä',
                    'liekki',
                    'liina',
                    'lintu',
                    'lippu',
                    'lohi',
                    'loiste',
                    'luku',
                    'lumi',
                    'lumo',
                    'luokka',
                    'luonto',
                    'maa',
                    'maailma',
                    'maasto',
                    'maila',
                    'majakka',
                    'manner',
                    'mansikka',
                    'marmori',
                    'matka',
                    'mehiläinen',
                    'meri',
                    'metsä',
                    'miekkavalas',
                    'mustikka',
                    'muurahainen',
                    'myrsky',
                    'mäki',
                    'nappi',
                    'nauha',
                    'neptunus',
                    'niemi',
                    'niitty',
                    'numero',
                    'nummi',
                    'nuotio',
                    'oiva',
                    'oksa',
                    'olki',
                    'omena',
                    'onki',
                    'onni',
                    'opas',
                    'oppi',
                    'orava',
                    'otso',
                    'ovi',
                    'paiste',
                    'pala',
                    'pallo',
                    'paperi',
                    'pelikaani',
                    'perho',
                    'peura',
                    'pihlaja',
                    'pino',
                    'pinta',
                    'pisara',
                    'pluto',
                    'pohjanmeri',
                    'polku',
                    'portti',
                    'pronssi',
                    'puhe',
                    'puhuri',
                    'punainen',
                    'puomi',
                    'purje',
                    'puro',
                    'puu',
                    'pyy',
                    'päivä',
                    'raikas',
                    'raita',
                    'raita',
                    'ranta',
                    'rastas',
                    'rata',
                    'ratas',
                    'rauta',
                    'reitti',
                    'repo',
                    'reppu',
                    'retki',
                    'riemu',
                    'ruis',
                    'rusko',
                    'ruusu',
                    'sade',
                    'sakset',
                    'salmi',
                    'sammal',
                    'sarja',
                    'sato',
                    'sauna',
                    'savu',
                    'siipi',
                    'silta',
                    'simpukka',
                    'sininen',
                    'sisko',
                    'sivu',
                    'sointu',
                    'soitin',
                    'solki',
                    'sudenkorento',
                    'sulka',
                    'sumu',
                    'syksy',
                    'syksy',
                    'syys',
                    'sävel',
                    'taika',
                    'taimen',
                    'taimi',
                    'taito',
                    'taituri',
                    'talo',
                    'talvi',
                    'tammi',
                    'tanner',
                    'tarha',
                    'tasku',
                    'tassu',
                    'telkkä',
                    'tellus',
                    'tenho',
                    'teos',
                    'tiainen',
                    'tie',
                    'tieto',
                    'tiikeri',
                    'tiira',
                    'tikka',
                    'tikkari',
                    'tikli',
                    'tilke',
                    'timantti',
                    'tina',
                    'torni',
                    'touko',
                    'tovi',
                    'tuli',
                    'tunti',
                    'tunturi',
                    'tuohi',
                    'tuokio',
                    'tuoli',
                    'tuomi',
                    'tuore',
                    'tupa',
                    'tuuli',
                    'tyvi',
                    'tähti',
                    'täky',
                    'uikku',
                    'ukko',
                    'ulappa',
                    'usva',
                    'vaaka',
                    'vadelma',
                    'vahti',
                    'valkoinen',
                    'valo',
                    'varpunen',
                    'vasta',
                    'vasu',
                    'vene',
                    'vene',
                    'veto',
                    'vety',
                    'vihreä',
                    'vihta',
                    'viima',
                    'viiri',
                    'vimma',
                    'violetti',
                    'virta',
                    'voima',
                    'vuori',
                    'vuoro',
                    'välke',
                ],
                fields: {
                    last_login: 'Kirjautunut',
                    registered: 'Rekisteröitynyt',
                    name: 'Nimi',
                    username: 'Sähköposti',
                    license_status: 'Lisenssin tila',
                    licenses: 'Käytössä',
                    password: 'Salasana',
                    public: {
                        firstname: 'Etunimi',
                        middlename: 'Toinen nimi',
                        lastname: 'Sukunimi',
                        code: 'Avainkoodi',
                    },
                    school: 'Koulu',
                    class: 'Luokka',
                    group_name: 'Luokka',
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                    services: 'Lähteet',
                },
                tabs: {
                    profile: 'Perustiedot',
                    schools_and_groups: 'Koulut ja ryhmät',
                    materials: 'Käytössä',
                    merge: 'Yhdistäminen',
                },
                page: {
                    add_pupils_title: 'Lisää luokalle oppilaita',
                    delete: 'Poista oppilas',
                    create: 'Lisää oppilas',
                    fromfile: 'Lisää CSV tiedostosta',
                },
                status: {
                    0: 'aktiivinen',
                    1: 'Poistettu',
                    2: 'Rekisteröinti kesken',
                    3: 'Rekisteröitymätön',
                },
                help: {
                    never_used: 'Ei kirjautunut',
                    createform:
                        'Avainkoodi luodaan oppilaalle lisäyksen yhteydessä.',
                    createnewkey: 'Luo uusi avainkoodi oppilaalle',
                },
                empty_header: 'Ei oppilaita',
            },
            events: {
                name: 'Tapahtuma |||| Tapahtumat',
            },
            transfer: {
                name: 'Siirto/yhdistäminen |||| Siirto/yhdistäminen',
                fields: {
                    name: 'Ikoni',
                    item_type: 'Tyyppikoodi',
                    item_type_name: 'Tyyppi',
                    description_field1: 'Kuvaus 1',
                    description_field2: 'Kuvaus 2',
                    description_field3: 'Kuvaus 3',
                },
                filters: {
                    category: 'Sarja',
                    status: 'Tila',
                    type: 'Tyyppi',
                    languages: 'Kieli',
                    target_persons: 'Kohderyhmä',
                    kouluasteet: 'Kouluaste',
                    applications: 'Aplikaatiot',
                },
            },
            subproduct: {
                empty_header: 'Ei alituotteita',
                empty_message: 'Lisää alituotteita alla olevalla lomakkeella.',
            },
            product: {
                name: 'Tuote |||| Tuotteet',
                fields: {
                    id: 'Tilauslinkki',
                    name: 'Nimi',
                    'public.alternative_name': 'Vaihtoehtoinen nimi',
                    'private.tuoterekisteri_data.tuotenimi':
                        'Nimi tuoterekisterissä',
                    'public.category': 'Kirjasarja',
                    //language: 'Kieli',
                    'public.languages': 'Kielet',
                    'public.target_persons': 'Kohderyhmä',
                    'public.show_testusage_button': 'Näytä koekäyttö-painike',
                    'public.kouluasteet': 'Kouluaste',
                    'public.applications': 'Applikaatiot joissa näytetään',
                    status: 'Tila',
                    'public.product_type': 'Tuotetyyppi',
                    'public.modules': 'Moduulit',
                    'public.not_period_license': 'Ei kausilisenssituote',
                    is_group: 'Ryhmä',
                    'public.materialstorage.isbn': 'ISBN',
                    'public.materialstorage.alternative_isbn':
                        'ISBN (vikasieto)',
                    path: 'Polku',
                    filename: 'Tiedosto',
                    show_only_in_list: 'Näkyvyys',
                    show_only_in_list_input: 'Älä näytä yksittäisenä tuotteena',
                    show_only_to_subscribers: 'Näytä vain tilaajille',
                    'public.dont_show_as_main_product':
                        'Näytä päätuotteena vain tilaajille',
                    'public.dont_show_as_sub_product':
                        'Älä näytä alituotteena Opepalvelussa',
                    free_to_download: 'Tuote on ilmainen',
                    'public.default_license_type': 'Lisenssityyppi',
                    'public.default_license_period': 'Lisenssin oletuskesto',
                    'public.disable_extra_material':
                        'Älä salli käyttöönottoa ISBN:llä',
                    'public.is_saamelaiskaraja': 'Saamelaiskäräjätuote',
                    'public.materialstorage.default_trial_period':
                        'Koekäytön oletuskesto',
                    'public.materialstorage.mime_type': 'Tiedostotyyppi',
                    'public.materialstorage.path': 'Polku',
                    materialstorage_guid: 'Materiaalivarasto',
                    description: 'Esittelyteksti/Markkinointiteksti',
                    'private.tuoterekisteri_data.saatavuus': 'KV-saatavuus',
                    'private.tuoterekisteri_data.tl_tuote_esittelyteksti_info':
                        'Esittelyteksti/Markkinointiteksti',
                    'private.tuoterekisteri_data.kantanumero': 'Kantanumero',
                    'public.subject': 'Opetusaine',
                    'public.additional_subjects': 'Muut opetusaineet',
                    'public.course': 'Kurssi',
                    'public.element': 'Elementti',
                    'public.class_level': 'Luokka-aste',
                    'public.productgroup': 'Tuoteryhmä',
                    'public.is_for_extra': 'Kuuluu lukion käyttäjäetuihin',
                    'public.is_package': 'Pakettituote',
                    is_for_extra_first:
                        'Kuuluu lukion ensimmäisen vuoden käyttäjäetuihin',
                    'public.show_high_school_groups':
                        'Kuuluu lukion ryhmävalintaan',
                    'public.release_date': 'Lisätieto',
                    'public.free_to_download': 'Ilmainen',
                    order: 'Järjestä',
                    'public.sumplattava': 'Tuote on sumplattava',
                    saatavuus: 'Saatavuus',
                    modules: 'Moduulit',
                    'public.extendable': 'Lisenssi on jatkettavissa',
                    'public.extendable_days': 'Jatkoaika päivinä',
                    'user.firstname': 'Etunimi',
                    'user.lastname': 'Sukunimi',
                    'user.email': 'Sähköposti',
                    'license.valid_from': 'Mistä',
                    'license.valid_until': 'Mihin',
                    'subscriber.name': 'Tilaaja',
                    'subscription.name': 'Jaettu / Tilattu',
                },
                filters: {
                    category: 'Sarja',
                    category_close: 'Sarja',
                    status: 'Tila',
                    status_close: 'Tila',
                    type: 'Tyyppi',
                    type_close: 'Tyyppi',
                    languages: 'Kieli',
                    languages_close: 'Kieli',
                    target_persons: 'Kohderyhmä',
                    target_persons_close: 'Kohderyhmä',
                    kouluasteet: 'Kouluaste',
                    kouluasteet_close: 'Kouluaste',
                    applications: 'Applikaatiot',
                    applications_close: 'Applikaatiot',
                    materialstorage: 'Materiaalivarasto',
                    materialstorage_close: 'Materiaalivarasto',
                    kvstatus: 'Saatavuus',
                    kvstatus_close: 'Saatavuus',
                },
                tabs: {
                    details: 'Perustiedot',
                    extra_details: 'Lisätiedot',
                    redirect_details: 'Ohjaustiedot',
                    tuotelista_details: 'Tuoterekisteritiedot',
                    history_details: 'Historia',
                    sub_details: 'Liitetyt tuotteet',
                    modify_history: 'Muutoshistoria',
                    usage_history: 'Käyttöhistoria',
                    licenses: 'Lisenssit',
                },
            },
            subscription: {
                name: 'Tilaus |||| Tilaukset',
                fields: {
                    name: 'Nimi',
                    subscriber: 'Tilaaja',
                    product: 'Tuote',
                    product_guid: 'Tuote',
                    subscriber_guid: 'Tilaaja',
                    valid_from: 'Mistä',
                    valid_until: 'Mihin',
                    valid_season: 'Voimassa',
                    license_count: 'Ostettu',
                    used_license_count: 'Lunastettu',
                    used_license_teacher_count: 'Käytössä',
                    unused_license_count: 'Vapaana',
                    type: 'Lisenssityyppi',
                    product_code: 'Tuotekoodi',
                    activate_code: 'Aktivointikoodi',
                    message: 'Muutoksen syy',
                    license_user_name: 'Käyttäjä',
                    license_created_date: 'Käyttöönotto',
                    license_modified_date: 'Muokattu',
                    status: 'Tila',
                    srcond: 'Kohdistus',
                    created_start: 'Luotu (mistä)',
                    created_end: 'Luotu (mihin)',
                    modified_start: 'Päivitetty (mistä)',
                    modified_end: 'Päivitetty (mihin)',
                    created: 'Luotu',
                    modified: 'Päivitetty',
                    'product.name': 'Tuote',
                    'subscriber.name': 'Tilaaja',
                    'public.kausi': 'Kausi',
                    'public.kvean': 'EAN',
                    'public.kvnro': 'Tilausnumero(t)',
                    'public.kvsource': 'Lähde',
                    'public.kvetumaara': 'Etumäärä',
                    'public.kvkoulukoodi': 'Koulukoodi',
                    'public.kvtilausmaara': 'Tilausmäärä',
                },
                filters: {
                    status: 'Tila',
                    status_close: 'Tila',
                    products: 'Tuote',
                    'products.search': 'Anna hakusanat',
                    products_close: 'Tuote',
                    subscribers: 'Tilaaja',
                    'subscribers.search': 'Anna hakusanat',
                    subscribers_close: 'Tilaaja',
                    isvalid: 'Voimassaolo',
                    isvalid_close: 'Voimassaolo',
                },
                tabs: {
                    details: 'Perustiedot',
                    license_details: 'Käytössä',
                    messages: 'Muistiinpanot',
                    public_details: 'Ohjaustiedot',
                    private_details: 'Tuoterekisteritiedot',
                    log: 'Tapahtumaloki',
                    modify_history: 'Muutoshistoria',
                    usage_history: 'Käyttöhistoria',
                    groups: 'Ryhmät',
                    kv: 'Kirjavälitys',
                },
                page: {
                    add_license: 'Jaa lisenssi',
                },
                action: {
                    accept: 'Lisää yksi',
                    reject: 'Poista yksi',
                },
                actions: {
                    remove: {
                        title: 'Huom !',
                    },
                },
                notification: {
                    approved_success: 'Lisäys onnistui',
                    approved_error: 'Virhe: Lisäys epäonnistui',
                    rejected_success: 'Poisto onnistui',
                    rejected_error: 'Virhe: Poisto epäonnistui',
                },
                license_status: {
                    0: 'Käytössä',
                    1: 'Poistettu',
                    2: 'Annettu',
                },
                license_action: {
                    delete: 'Poista',
                    unuse: 'Vapauta',
                },
                status: {
                    0: 'Julkinen',
                    1: 'Poistettu',
                },
                srcond: {
                    1: 'Kohdistamattomat',
                    2: 'Kohdistetut',
                },
            },
            userlicense: {
                name: 'Lisenssi |||| Lisenssit',
                fields: {
                    lastname: 'Sukunimi',
                    firstname: 'Etunimi',
                    email: 'Sähköposti',
                    product: 'Tuotenimi',
                    product_isbn: 'ISBN',
                    licenses: 'Lisenssit',
                    groups: 'Valitse ryhmä',
                },
            },
            userlist: {
                name: 'Käyttäjä |||| Käyttäjät',
                fields: {
                    lastname: 'Sukunimi',
                    firstname: 'Etunimi',
                    middlename: 'Toinen nimi',
                    username: 'Tunnus',
                    email: 'Sähköposti',
                    role: 'Rooli',
                    subjects: 'Opetusaineet',
                    code: 'Avainkoodi',
                },
            },
            school: {
                label: 'Oppilaitos',
                name: 'Oppilaitos |||| Oppilaitokset',
                fields: {
                    empty: '(Ei annettu)',
                    empty_web_code:
                        'Verkkokauppatunnusta ei löytynyt, ota yhteys Otavan asiakaspalveluun',
                    category_id: 'Categor',
                    name: 'Nimi',
                    keyfields: 'Avaintiedot',
                    keyfields_more:
                        'Avaintiedot (koulukoodi, -tyyppi ja -paikka)',
                    material: 'Materiaali',
                    public: {
                        hidepages: 'Piilota käyttäjiltä',
                        categories: 'Sarjat',
                        features: 'Debug features',
                        address: 'Postiosoite',
                        city: 'Kaupunki',
                        code: 'Koulukoodi',
                        name: 'Nimi',
                        type: 'Koulutyyppi',
                        school_email: 'Koulun virallinen sähköposti sääntö',
                        phone_1: 'Opettajahuoneen puhelin 1',
                        phone_2: 'Opettajahuoneen puhelin 2',
                        location: 'Paikka',
                        zip_code: 'Postinumero',
                        grade_max: 'Ylin luokka-aste',
                        grade_min: 'Alin luokka-aste',
                        headmaster: 'Rehtori',
                        identifier: 'Tunniste',
                        kvpwd: 'Salasana',
                        visit_city: 'Käynti kaupunki',
                        last_checked: 'Viimeisin tarkistus',
                        office_phone: 'Kanslian puhelin',
                        visit_address: 'Käynti osoite',
                        visit_zip_code: 'Käynti postinumero',
                        headmaster_email: 'Rehtorin sähköposti',
                        headmaster_phone: 'Rehtorin puhelin',
                        headmaster_cellphone: 'Rehtorin gsm',
                        number_of_pupils: 'Oppilaiden lukumäärä',
                        number_of_teachers: 'Opettajien lukumäärä',
                        grades: 'Luokka-asteet',
                        web_code: 'Verkkokauppatunnus',
                    },
                    'private.services.crm.crmGUID': 'CRM -linkki',
                },
                filters: {
                    types: 'Koulutyypit',
                    types_close: 'Koulutyypit',
                    type_groups: 'Koulutyyppiryhmät',
                    type_groups_close: 'Koulutyyppiryhmät',
                    status: 'Tila',
                    status_close: 'Tila',
                },
                actions: {
                    show_web_shop_code: 'Näytä verkkokauppatunnus',
                    remove: {
                        title: 'Huom !',
                        ask: 'Poista käyttäjä oppilaitoksesta %{name}',
                        description:
                            'Poistetaanko käyttäjä oppilaitoksesta %{name} ?',
                    },
                    add: {
                        title: 'Huom !',
                        ask: 'Palauta käyttäjä oppilaitokseen %{name}',
                        description:
                            'Palautetaanko käyttäjä oppilaitokseen %{name} ?',
                    },
                },
                page: {
                    manage_license: 'Oppilaslisenssit',
                    pupil_license: {
                        addall: 'Valitse kaikki',
                        removeall: 'Poista valinnat',
                        chosen: 'Valittu',
                    },
                    student_license: {
                        code: 'Aktivointikoodi',
                        name: 'Nimi',
                        aktivated: 'Käyttöönotto',
                    },
                    web_code:
                        'Verkkokauppatunnusta käytetään Otavan verkkokaupassa.',
                    web_code_username: 'Tunnus: %{username}',
                    web_code_password: 'Salasana: %{password}',
                    orders: {
                        pupil: 'Koulun oppilaslisenssit',
                        school: 'Koulun opetusaineistojen ja digikirjojen lisenssit',
                        past: 'Vanhentuneet',
                        active: 'Voimassa olevat',
                        future: 'Tulevat',
                    },
                },
                help: {
                    manage_license: {
                        product: 'Materiaali',
                        hint: 'Valitse materiaali',
                        beforeProductSelected:
                            'Aloita oppilaslisenssien jakaminen valitsemalla materiaali alla olevasta pudotusvalikosta.',
                        afterProductSelected:
                            'Valitse listalta oppilaat, joille haluat lisätä oppilaslisenssin. Muista lopuksi tallentaa muutokset klikkaamalla Tallenna painiketta. Oppilaat ovat listattu luokittain. Voit tarvittaessa vielä lisätä uusia oppilaita luokkiin klikkaamalla lisää oppilas painiketta luokan kohdalta.',
                        afterProductSelected2:
                            'Voit myös poistaa oppilaslisenssin oppilaalta, joka ei vielä ole käyttänyt materiaalia.',
                    },
                },
                tabs: {
                    details: 'Perustiedot',
                    subscription: 'Tilaukset',
                    groups: 'Ryhmät',
                    teachers: 'Opettajat',
                    pupils: 'Oppilaat',
                    students: 'Opiskelijat',
                },
            },
            group: {
                name: 'Ryhmä |||| Ryhmät',
                fields: {
                    name: 'Nimi',
                    status: 'Tila',
                    parent_group: 'Oppilaitos', 
                    schools_name: 'Oppilaitokset',
                    'public.code': 'Liittymiskoodi',
                    'public.group_type': 'Ryhmätyyppi',
                    'public.archived': 'Arkistoitu',
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                },
                tabs: {
                    details: 'Perustiedot',
                },
                filters: {
                    status: 'Tila',
                    status_close: 'Tila',
                    archived: 'Arkistointi',
                    archived_close: 'Arkistointi',
                    schools: 'Oppilaitokset',
                    schools_close: 'Oppilaitokset',
                    'schools.search': 'Hae oppilaitosta',
                },
            },
            announcement: {
                name: 'Tiedote |||| Tiedotteet',
                fields: {
                    name: 'Nimi',
                    'public.message': 'Teksti',
                    valid_from: 'Mistä',
                    valid_until: 'Mihin',
                    valid_season: 'Voimassa',
                    status: 'Tila',
                    'public.applications': 'Palvelut',
                    placements: 'Sijainti',
                    'public.languages': 'Kieli',
                    'public.categories': 'Sarjat',
                    'public.types': 'Koulutyypit',
                    'public.tags':
                        'ISBN kohdennus (pilkkueroiteltu lista ISBN:stä)',
                    'public.typegroups': 'Koulutyyppiryhmät',
                    timestamp: 'Muokattu',
                    'public.announcement_type': 'Tiedotetyyppi',
                    created: 'Luotu',
                    modified: 'Muokattu',
                    'creator_name': 'Luoja',
                    'modifier_name': 'Muokkaaja',
                    author: {
                        creator: 'Luoja',
                        created: 'Luotu',
                        modifier: 'Muokkaaja',
                        modified: 'Muokattu',
                    },
                },
                filters: {
                    status: 'Tila',
                    status_close: 'Tila',
                    languages: 'Kieli',
                    languages_close: 'Kieli',
                    applications: 'Applikaatiot',
                    applications_close: 'Applikaatiot',
                    announcement_types: 'Tiedotetyyppi',
                    announcement_types_close: 'Tiedotetyyppi',
                    online_status: 'Voimassaolo',
                    online_status_close: 'Voimassaolo',
                },
                tabs: {
                    details: 'Perustiedot',
                },
                notification: {
                    approved_success: 'Lisäys onnistui',
                    approved_error: 'Virhe: Lisäys epäonnistui',
                    rejected_success: 'Poisto onnistui',
                    rejected_error: 'Virhe: Poisto epäonnistui',
                },
                status: {
                    0: 'Julkinen',
                    1: 'Poistettu',
                    2: 'Luonnos',
                },
                language: {
                    fin: 'Suomenkielinen',
                    swe: 'Ruotsinkielinen',
                    eng: 'Englanninkielinen',
                },
                application: {
                    oppimisenpalvelut: 'oppimisenpalvelut.otava.fi',
                    opepalvelu: 'opepalvelu.otava.fi',
                    opepalvelubeta: 'opepalvelu-beta.otava.fi',
                    oppilas: 'oppilas.otava.fi',
                    opiskelija: 'opiskelija.otava.fi',
                    finnlectura: 'finnlectura',
                    finnlectura_opiskelija: 'opiskelija.finnlectura.fi',
                    finnlectura_opettaja: 'opettaja.finnlectura.fi',
                },
                placement: {
                    1: 'Ylä banneri',
                    2: 'Ylä banneri alempi',
                    3: 'Sarjan yhteydessä',
                    4: 'Uutinen sarjan yhteydessä',
                    5: 'Viestilaatikossa',
                },
            },
            application: {
                label: 'Palvelu',
                name: 'Palvelu |||| Palvelut',
                actions: {
                    remove: {
                        title: 'Huom !',
                        ask: 'Poista käyttäjä palvelusta %{name}',
                        description:
                            'Poistetaanko käyttäjä palvelusta %{name} ?',
                    },
                },
                tabs: {
                    details: 'Perustiedot',
                },
                fields: {
                    last_login: 'Kirjautunut',
                    registered: 'Rekisteröitynyt',
                    name: 'Nimi',
                    username: 'Sähköposti',
                    alternative_isbn_in_use: 'Korvaava ISBN käytössä',
                    alternative_exclude_materialstorage:
                        'Älä käytä näissä materiaalivarastoissa',
                    alternative_exclude: 'Älä käytä näillä ISBN:llä',
                    alternative_isbn_text_fi: 'Suomenkielinen teksti',
                    alternative_isbn_text_sv: 'Ruotsinkielinen teksti',
                },
                status: {
                    0: 'aktiivinen',
                    1: 'Poistettu',
                },
                role: {
                    300: 'Pääkäyttäjä',
                    250: 'Tuotepäivittäjä',
                },
            },
            logevent: {
                s: {
                    filter: {
                        date_from: 'Mistä',
                        date_to: 'Mihin',
                    },
                    fields: {
                        date: 'Ajankohta',
                        author: 'Käyttäjä',
                        title: 'Lisenssimäärä',
                        subtitle: 'Lisenssimäärän muutos',
                        description: 'Viesti',
                    },
                },
                p: {
                    filter: {
                        date_from: 'Mistä',
                        date_to: 'Mihin',
                    },
                    fields: {
                        date: 'Ajankohta',
                        author: 'Käyttäjä',
                        title: '',
                        subtitle: 'Private',
                        description: 'Public',
                    },
                },
            },
            locks: {
                overlay: 'Currently Edited by %{name}',
            },
        },
        login: {
            otava_ad: 'Otava kirjautuminen',
            otava_ad_success: 'Jatka palveluun',
            otava_ad_failure: 'Jatka kirjautumis sivulle',
            nonce_mismatch:
                'Tunnuksellasi on kirjauduttu toisessa selaimessa. Kirjaudu uudelleen.',
            no_token: 'Kirjaudu sisään',
        },
    }
);

export default customFinnishMessages;
