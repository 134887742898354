import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useNotify, useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

interface Props {
  label?: string;
  sortable?: boolean;
}

const CustomLogAsTeacherButton = (props: Props) => {

  const record = useRecordContext();
  const notify = useNotify();
  const application = '0e274549-7a89-4313-8720-0f4588a51f98';
  const ttl = 60;

  const handleClick = (e) => {
    e.stopPropagation();

    var api="";

    try{
      api = `${process.env.REACT_APP_INFRA_API}/aspa/create_auth_token`;
      console.log(api);
    }catch(e){
      notify(`notifications.WRONG_PARAMETERS`, { type: 'warning' });
      return;
    }

    const token = localStorage.getItem('token');
    fetch(api, {
      method: 'POST',
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${token}`, 
         'Content-Type': 'application/json'
       },
        body: JSON.stringify({
          user_guid: record.id,
          app_id: application,
          ttl:ttl,
      })
    })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json();
    })
    .then(data => {

      console.log(data);

      if (/-dev/.test(window.location.hostname))
        window.open('https://oppimateriaalit-id-dev.otava.fi/v1/oidc/teacher/login?authtoken=' + data.token + '&hash=' + data.hash, '_blank');
      else if (/-next/.test(window.location.hostname))  
        window.open('https://oppimateriaalit-id-next.otava.fi/v1/oidc/teacher/login?authtoken=' + data.token + '&hash=' + data.hash, '_blank');
      else if (/-beta/.test(window.location.hostname))  
        window.open('https://oppimateriaalit-id-beta.otava.fi/v1/oidc/teacher/login?authtoken=' + data.token + '&hash=' + data.hash, '_blank');
      else if (/localhost/.test(window.location.hostname))        
        window.open('http://localhost:8086/v1/oidc/student/login?authtoken=' + data.token + '&hash=' + data.hash, '_blank');
      else
        window.open('https://oppimateriaalit-id.otava.fi/v1/oidc/teacher/login?authtoken=' + data.token + '&hash=' + data.hash, '_blank');
    })
    .catch((e) => {
      console.error(e);
      let message = 'MATERIAL_PREVIEW_ERROR';
      notify(`notifications.${message.toString().toUpperCase()}`, { type: 'warning' });
    });
  }

  return (
    <>
        <Button onClick={handleClick} disabled={record.status != 0}><RemoveRedEyeIcon /></Button>
    </>
  );
};

CustomLogAsTeacherButton.defaultProps = {
  label: 'Esikatselu',
  sortable: false,
};

export default CustomLogAsTeacherButton;