import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useNotify, useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

interface Props {
  label?: string;
  sortable?: boolean;
}

const CustomPreviewMaterialButton = (props: Props) => {

  const record = useRecordContext();
  const notify = useNotify();

  const handleClick = (e) => {
    e.stopPropagation();

    var api="";

    try{
      api = `${process.env.REACT_APP_INFRA_API}/redirect`;
      console.log(api);
    }catch(e){
      notify(`notifications.WRONG_PARAMETERS`, { type: 'warning' });
      return;
    }

    const token = localStorage.getItem('token');
    fetch(api, {
      method: 'POST',
      headers: {
         'Accept': 'application/json',
         'Authorization': `Bearer ${token}`, 
         'Content-Type': 'application/json'
       },
        body: JSON.stringify({
        material: record.id,
        preview: 1,
      })
    })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      return response.json();
    })
    .then(data => {

      console.log(JSON.stringify(data.redisData));                        
                  
    //Ohjaus
    //window.location.href=data.url + '?redirtoken=' + data.token;
      if(data.url.indexOf('amazonaws.com') !== -1){
        window.open(data.url, '_blank');
        //window.location.href=data.url;
      //window.open(data.url,'preview');
      }else{
        window.open(data.url + '?redirtoken=' + data.token, '_blank');
        //window.location.href=data.url + '?redirtoken=' + data.token;
        //window.open(data.url + '?redirtoken=' + data.token,'preview');
      }
    })
    .catch((e) => {
      console.error(e);
      let message = 'MATERIAL_PREVIEW_ERROR';
      notify(`notifications.${message.toString().toUpperCase()}`, { type: 'warning' });
    });
  }

  return (
    <>
        <Button onClick={handleClick}><RemoveRedEyeIcon /></Button>
    </>
  );
};

CustomPreviewMaterialButton.defaultProps = {
  label: 'Esikatselu',
  sortable: false,
};

export default CustomPreviewMaterialButton;