import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    Divider,
    Chip
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';
import OtavaAdLogin from '../components/OtavaAdLogin';
const Login = () => {

    const [loading, setLoading] = useState(false);
    const translate = useTranslate();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const prosessing = location.pathname.startsWith('/oidc-login')

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    //background:'url(https://source.unsplash.com/random/1600x900)',
                    //backgroundRepeat: 'no-repeat',
                    //backgroundSize: 'cover',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Avatar sx={{ bgcolor: 'secondary.main' }}>
                            <LockIcon />
                        </Avatar>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                    </Box>
                    
                    {process.env.REACT_APP_INFRA_LOGIN_ENABLED === 'true' && !prosessing &&
                    <Form onSubmit={handleSubmit} noValidate>
                    <Box>
                        <Box sx={{ padding: '0 1em 1em 1em' }}>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    autoFocus
                                    source="username"
                                    label={translate('ra.auth.username')}
                                    disabled={loading}
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                            <Box sx={{ marginTop: '1em' }}>
                                <TextInput
                                    source="password"
                                    label={translate('ra.auth.password')}
                                    type="password"
                                    disabled={loading}
                                    validate={required()}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                        <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                {translate('ra.auth.sign_in')}
                            </Button>

                        </CardActions>
                    </Box>
                    </Form>
                    }
                    {process.env.REACT_APP_INFRA_OTAVA_AD_LOGIN_ENABLED === 'true' && process.env.REACT_APP_INFRA_LOGIN_ENABLED === 'true' && !prosessing &&
                    <Divider variant="middle"><Chip label="TAI" /></Divider>
                    }
                    {process.env.REACT_APP_INFRA_OTAVA_AD_LOGIN_ENABLED === 'true' &&
                    <OtavaAdLogin />
                    }
                </Card>
            </Box>
        </Box>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
