import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    Labeled,
    TextField,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box } from '@mui/material';


import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
    CustomUserLicenseByProductList,
} from '../controls';

import { 
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
 } from '@mui/icons-material';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const ProductEdit = () => {

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />} title='' >
                <FormTab label="resources.product.tabs.details" icon={<Article />}>
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Labeled>
                                    <TextField resource="product" source="private.tuoterekisteri_data.tuotenimi" />
                                </Labeled>
                                {/* <CustomTextField resource="product" source="private.tuoterekisteri_data.tuotenimi" /> */}
                                <TextInput resource="product" source="name" validate={[required()]} fullWidth />
                                <TextInput resource="product" source="public.alternative_name" fullWidth />
                                <CustomSelectInput resource="product" source="public.category" url="extralist/product/categories" fullWidth />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Labeled>
                                    <TextField resource="product" source="private.tuoterekisteri_data.tl_tuote_esittelyteksti_info" />
                                </Labeled>
                                {/* <CustomTextField resource="product" source="private.tuoterekisteri_data.tl_tuote_esittelyteksti_info" /> */}
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomSelectInput resource="product" source="public.product_type" url="extralist/product/types" fullWidth /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomSelectInput resource="product" source="public.element" url="extralist/product/elements" fullWidth /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomSelectInput resource="product" source="public.productgroup" url="extralist/product/productgroups" fullWidth /></Box>
                                </Box>

                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomSelectInput resource="product" source="public.subject" url="extralist/product/subjects" fullWidth /></Box>
                                    <Box flex={2} mr={{ xs: '0.5em' }}><CustomSelectArrayInput resource="product" source="public.additional_subjects" url="extralist/product/subjects" fullWidth /></Box>
                                </Box>

                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><TextInput resource="product" source="public.course" fullWidth /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><TextInput resource="product" source="public.class_level" fullWidth /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomSelectArrayInput resource="product" source="public.modules" url="extralist/product/modules" fullWidth /></Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="product" source="public.languages" url="extralist/product/languages" /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="product" source="public.target_persons" url="extralist/product/target_persons" /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="product" source="public.kouluasteet" url="extralist/product/kouluasteet" /></Box>
                                </Box>

                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: '0.5em' }}>
                                        <CustomRadioButtonGroupInput resource="product" source="status" url="extralist/product/statuses" parse={v => parseInt(v)} />
                                        <TextInput resource="product" source="public.release_date" fullWidth />
                                    </Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomRadioButtonGroupInput resource="product" source="public.default_license_type" url="extralist/product/license_types" parse={v => parseInt(v)} /></Box>
                                    <Box flex={1} mr={{ xs: '0.5em' }}><CustomCheckboxGroupInput resource="product" source="public.applications" url="extralist/product/applications" /></Box>
                                </Box>
                            </Grid>

                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.product.tabs.extra_details" icon={<LibraryBooks />} path="lisatiedot">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={4}>
                                <BooleanInput resource="product" source="public.dont_show_as_main_product" />
                                <BooleanInput resource="product" source="public.is_package" />
                                <BooleanInput resource="product" source="public.not_period_license" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                    <BooleanInput resource="product" source="public.dont_show_as_sub_product" />
                                    <BooleanInput resource="product" source="public.is_for_extra" />
                                    <CustomNumberInput resource="product" source="public.default_license_period" type="int" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <BooleanInput resource="product" source="public.sumplattava" />
                                <BooleanInput resource="product" source="public.show_high_school_groups" />
                                <BooleanInput resource="product" source="public.disable_extra_material" />
                                <BooleanInput resource="product" source="public.is_saamelaiskaraja" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                    <BooleanInput resource="product" source="public.extendable" />
                                    <CustomNumberInput resource="product" source="public.extendable_days" type="int" />
                            </Grid>
                        </Grid>
                    
                </FormTab>
                <FormTab label="resources.product.tabs.redirect_details" icon={<Podcasts />} path="ohjaustiedot">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomSelectInput resource="product" source="materialstorage_guid" url="extralist/product/materialstorages" fullWidth />
                                <BooleanInput resource="product" source="public.show_testusage_button" fullWidth />
                                <NumberInput resource="product" source="public.materialstorage.default_trial_period" type="int" validate={minValue(1)} />
                                <TextInput resource="product" source="public.materialstorage.isbn" fullWidth />
                                <TextInput resource="product" source="public.materialstorage.alternative_isbn" fullWidth />
                                <TextInput resource="product" source="public.materialstorage.path" title="public.materialstorage.path" fullWidth />
                                <TextInput resource="product" source="public.materialstorage.mime_type" fullWidth />
                                <FileField resource="product" source="public.materialstorage.path" title="public.materialstorage.path" />
                                <FileInput label={false} multiple={true} resource="product" source="files" accept="application/x-zip-compressed,video/mp4,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/pdf,application/zip,application/rtf,audio/mod,audio/x-mod,audio/mpeg3,audio/x-mpeg-3,video/x-mpeg,video/mpeg,video/quicktime,video/avi,video/msvideo,video/x-msvideo,application/x-troff-msvideo,text/plain">
                                    <FileField resource="product" source="src" title="title" />
                                </FileInput>
                            </Grid>
                        </Grid>
                    
                </FormTab>
                {/* <FormTab label="resources.product.tabs.tuotelista_details" icon={<DataObject />} path="tuotelista">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomJSONPretty source="private.tuoterekisteri_data" indent={4} />
                            </Grid>
                        </Grid>
                    
                </FormTab> */}
                {/* 
                    - Tähän muokkaushistoria, aluksi samalla tavalla, kuin vanhassa.
                    - Jatkossa nämä voisi tuoda Elasticista. Esim. nappia painamalla hakisi tietyllä tavalla tagitetyt tiedot???
                    - Tähän voisi hakea koko historian kerralla
                */}
                {/* <FormTab label="resources.subscription.tabs.modify_history" icon={<History />} path="muokkaus_historia">

                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomLogEventList resource='logevent' disableSyncWithLocation customFilters={['resource_guid']} uselog="p" showExtra={[]} hideFields={[]} sort={{ field: 'created', order: 'DESC' }} ><span /></CustomLogEventList>
                            </Grid>
                        </Grid>

                </FormTab> */}
                {/* 
                    - Tähän käyttöhistoria.
                    - Jatkossa nämä voisi tuoda Elasticista. Esim. nappia painamalla hakisi tietyllä tavalla tagitetyt tiedot???
                    - esim. siirtyminen tilauksen tuotteeseen
                    - Tähän voisi hakea ekalla napin painalluksella esim. viimeisen kuukauden tiedot ja sitten vaikka seuraavilla painalluksilla lisää???
                */}
                {/* <FormTab label="resources.subscription.tabs.usage_history" icon={<TransferWithinAStation />} path="kaytto_historia">
                    
                </FormTab> */}
                <FormTab label="resources.product.tabs.sub_details" icon={<Attachment />} path="liitetyt_tuotteet">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomProductList resource='subproduct' disableSyncWithLocation customFilters={['parent_product_guid']} showExtra={['free_to_download','ordering']} hideFields={['category']} sortable={false} sort={{ field: 'sort_num', order: 'ASC' }} bulkActionButtons={<CustomBulkDeleteButton />}><span /></CustomProductList>
                                <CustomProductList resource='product' disableSyncWithLocation customFilters={['exclude_child_of_guid']} showExtra={['search']} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={<CustomBulkAddButton />}><span /></CustomProductList>
                            </Grid>
                        </Grid>
                    
                </FormTab>

                <FormTab label="resources.product.tabs.licenses" icon={<LibraryBooks />} path="lisenssit">
                    
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CustomUserLicenseByProductList resource='userlicense' disableSyncWithLocation customFilters={['product_guid']} showExtra={[]} hideFields={[]} sort={{ field: 'license.valid_until', order: 'DESC' }} bulkActionButtons={<CustomBulkDeleteButton />}><span /></CustomUserLicenseByProductList>
                            </Grid>
                        </Grid>
                    
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
}

export default ProductEdit;