import * as React from 'react';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { List, Datagrid, TextField, TextInput, useTranslate, useEditContext, FilterFormClasses, useListController, ListControllerProps, ListProps, useStore, DateField, FunctionField } from 'react-admin';
import CustomUrlBooleanInput from './CustomUrlBooleanInput';
import CustomUrlButton from './CustomUrlButton';
import CustomEmpty from './CustomEmpty';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomSelectInput from './CustomSelectInput';
import CustomUserLicenseField from './CustomUserLicenseField';
import ProductFullNameField from '../product/ProductFullNameField';
import SubscriberFullNameField from '../subscription/SubscriberFullNameField';

interface Props extends ListProps {
    bulkActionButtons?: false | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey: string;
}

const CustomUserLicenseByProductList = (props: Props) => {

    const translate = useTranslate();

    const parent = useEditContext();
    const filter = props.customFilters?.includes("product_guid") ? {"product_guid": parent.record.guid} :  undefined;
    //console.log(filter);
    return (
        <List {...props} actions={false} filter={filter} empty={<CustomEmpty />}>
            <Datagrid bulkActionButtons={props.bulkActionButtons}>
                {!props.hideFields?.includes("subscriber") && <SubscriberFullNameField source="subscriber" key="subscriber.name" label={translate('resources.product.fields.subscriber.name')} createLink={true} useIcon={true} sortable={props.sortable} />}
                {!props.hideFields?.includes("firstname") && <TextField source="user.firstname" key="user.firstname" label={translate('resources.product.fields.user.firstname')} sortable={props.sortable} />}
                {!props.hideFields?.includes("lastname") && <TextField source="user.lastname" key="user.lastname" label={translate('resources.product.fields.user.lastname')} sortable={props.sortable} />}
                {!props.hideFields?.includes("email") && <TextField source="user.email" key="user.email" label={translate('resources.product.fields.user.email')} sortable={props.sortable} />}
                {!props.hideFields?.includes("subscription") && <FunctionField source="subscription" render={record => `${record.subscription.used_license_count} / ${record.subscription.license_count}`} key="subscription.name" label={translate('resources.product.fields.subscription.name')} sortable={false} />}
                {!props.hideFields?.includes("valid_from") && <DateField source="license.valid_from" key="license.valid_from" label={translate('resources.product.fields.license.valid_from')} sortable={props.sortable} />}
                {!props.hideFields?.includes("valid_until") && <DateField source="license.valid_until" key="license.valid_until" label={translate('resources.product.fields.license.valid_until')} sortable={props.sortable} />}
            </Datagrid>
        </List>
    );
};

CustomUserLicenseByProductList.defaultProps = {
    sortable: true,
    showExtra: [],
    hideFields: [],
    customFilters: [],
    refreshKey: 'refreshAll',
};

export default CustomUserLicenseByProductList;
